// BlurOverlay.js
import React from 'react';
import "../css/style.css"
import { useBlur } from './context/BlurContext';

const BlurOverlay = () => {
    const { isBlurred } = useBlur();

    return isBlurred ? <div className="blur-overlay"></div> : null;
};

export default BlurOverlay;
