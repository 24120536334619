// home.js
import React, {useEffect, useState} from 'react';
import Navbar from './Navbar';
import Skills from './Skills';
import CV from './CV';
import Projet from './Project';
import Contact from './Contact';
import '../css/home.css'
import { useDarkMode } from './context/DarkModeContext';
import {useScrollToTopValue} from './context/ScrollToTopContext';

function ScrollToTop() {
    const { isDarkMode } = useDarkMode();
    const { showUpTop, setShowUpTop } = useScrollToTopValue();
    const [isAnimating, setIsAnimating] = useState(false);

    const handleScroll = () => {
        if (window.pageYOffset > 300) {
            setShowUpTop(true);
        } else {
            setShowUpTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleClick = () => {
        setIsAnimating(true);
        window.scrollTo({top: 0, behavior: "smooth"});
        setTimeout(() => setIsAnimating(false), 500);
    };
    return (
        <div>
            <div>
                <Navbar />
                <Skills/>
                <CV />
                <Projet />
                <Contact />
                {showUpTop && (
                    <div id="slideInPage" style={{display: "block"}}>
                        <div
                            className={`scroll-to-top ${isAnimating ? "slide-out" : "slide-in"}`}
                            onClick={handleClick}
                        >
                            <svg viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                {/* Cercle extérieur */}
                                <path
                                    d="M280 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                    fill="white"
                                    strokeWidth="16"
                                />
                                {/* Flèche intérieure */}
                                <path
                                    d="M143 284.9l72.4-75.5V392c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V209.4l72.4 75.5c9.3 9.7 24.8 9.9 34.3.4l10.9-11c9.4-9.4 9.4-24.6 0-33.9L273 107.7c-9.4-9.4-24.6-9.4-33.9 0L106.3 240.4c-9.4 9.4-9.4 24.6 0 33.9l10.9 11c9.6 9.5 25.1 9.3 34.4-.4z"
                                    fill={ '#123456'}
                                />
                            </svg>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ScrollToTop;
