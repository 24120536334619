// ScrollToTopContext.js
import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../css/home.css';

const ScrollToTopContext = createContext();

export function ScrollToTopProvider({ children }) {
    const [showUpTop, setShowUpTop] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setShowUpTop(scrollTop > 100); // Montre le bouton après 100px de défilement
        };

        // Appel initial pour définir l'état correct au chargement de la page
        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <ScrollToTopContext.Provider value={{ showUpTop, setShowUpTop }}>
            {children}
            {showUpTop && (
                <button
                    onClick={scrollToTop}
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        // Ajoutez d'autres styles selon vos besoins
                    }}
                >
                </button>
            )}
        </ScrollToTopContext.Provider>
    );
}

ScrollToTopProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export function useScrollToTopValue() {
    return React.useContext(ScrollToTopContext);
}
