import React, {useState, useEffect} from 'react';
import Home from "./components/Home";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Page from "./components/Page"
import PageCV from "./components/PageCV"
import Page2 from "./components/Page2";
import PageNotFound from "./components/404";
import {ScrollToTopProvider} from './components/context/ScrollToTopContext';
import {DarkModeProvider, useDarkMode} from './components/context/DarkModeContext';
import ContactPage from "./components/ContactPage";
import "../src/css/style.css";
import i18n from "i18next";
import {useTranslation} from 'react-i18next';
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from 'i18next-http-backend';
import {UAParser} from 'ua-parser-js';
import {BlurProvider} from "./components/context/BlurContext";
import BackgroundAnimation from "./components/animation/backgroundAnimation";
import '../src/css/app.css';

const deviceType = navigator.userAgent.match(/Android|iPhone|iPad|iPod|Windows Phone|webOS|BlackBerry|IEMobile|Opera Mini/i) ? 'Mobile' : 'Desktop';
let deviceName = "Unknown";
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    deviceName = "Mobile Device";
} else if (/Windows|Mac/i.test(navigator.userAgent)) {
    deviceName = "Desktop/Laptop";
} else if (/Linux/i.test(navigator.userAgent)) {
    deviceName = "Linux Device";
}

const supportedLanguages = ['en', 'fr'];
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: supportedLanguages,
        fallbackLng: "en",
        detection: {
            order: ['cookie', 'navigator', 'localStorage', 'path', 'subdomain'],
            caches: ['cookie'],
        },
        backend: {
            loadPath: '/assets/locales/{{lng}}/{{ns}}.json'
        },
        react: {useSuspense: false}
    });

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

let sessionId = getCookie('session_id');
if (!sessionId || sessionId.length !== 32) {
    // Si le cookie de session n'existe pas ou n'a pas une longueur de 32 caractères, en créer un nouveau
    sessionId = Array.from(crypto.getRandomValues(new Uint8Array(16)), (byte) => byte.toString(16).padStart(2, '0')).join('');
    document.cookie = `session_id=${sessionId}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
}
const handleContinueClick = () => {
    document.cookie = "SafariOk=true; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    document.body.classList.remove('no-scroll');
    window.location.reload(true);
};
//sentry
const Rolling = (w, h, color) => (<svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{
        margin: "auto", display: "block", shapeRendering: "auto",
    }}
    width={w}
    height={h}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
>
    <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={color}
        strokeWidth="6"
        r="28"
        strokeDasharray="110 40"
        style={{
            animation: "rotate 1s infinite", transformOrigin: "50% 50%", strokeLinecap: "round",
        }}
    />
    <style>
        {`
                @keyframes rotate {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                    }
                  `}
    </style>
</svg>);
// Import the ua-parser-js library
const parser = new UAParser();
const Safari = () => {
    const {t, i18n} = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        i18n.changeLanguage(language).then(() => setIsLoading(false));
    }, [language, i18n]);
    if (!getCookie('SafariOk')) {
        if (parser.getResult().browser.name === "Safari" && parser.getResult().browser.version <= "13") {
            // console.log(parser.getResult().browser.name + " / " + parser.getResult().browser.version);
            document.body.classList.add('no-scroll');
            return (
                <div id="overlay-container" className="overlay-container-app">
                    <BackgroundAnimation/>
                    <div>
                        <div>
                            <div className="divClose-app">
                                <span className="close-app" onClick={handleContinueClick}>×</span>
                            </div>
                            <div className="language-select-container-app">
                                <select
                                    value={language}
                                    onChange={(e) => setLanguage(e.target.value)}
                                    className="language-select-app"
                                >
                                    <option value="en">EN</option>
                                    <option value="fr">FR</option>
                                </select>
                            </div>
                            <div className="content-app">
                                {isLoading ? (
                                    <div>
                                        <p>Chargement...</p>
                                        <div className="loading-app">{Rolling(80, 80, "#000000")}</div>
                                    </div>
                                ) : (
                                    <>
                                        <h1>{t('safari.title')}</h1>
                                        <p>{t('safari.description')}</p>
                                        <p className="recommendation-app">{t('safari.recommendation')}</p>
                                        <button
                                            onClick={handleContinueClick}
                                            className="continue-button-app"
                                        >
                                            {t('safari.button')}
                                        </button>
                                        <p className="version-info-app">Safari version {parser.getResult().browser.version}</p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
    return null;
}


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            continueAnyway: false, // Variable d'état pour contrôler la continuation
        };
    }

    render() {
        return (
            <DarkModeProvider>
                <BlurProvider>
                    <BrowserRouter>
                        <ScrollToTopProvider>
                            <Safari/>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/CV" element={<PageCV/>}/>
                                <Route path="/Page" element={<Page/>}/>
                                <Route path="/Page2" element={<Page2/>}/>
                                <Route path="/Contact" element={<ContactPage/>}/>
                                <Route path="*" element={<PageNotFound/>}/>
                            </Routes>
                        </ScrollToTopProvider>
                    </BrowserRouter>
                </BlurProvider>
            </DarkModeProvider>
        );
    }
}

export default App;
