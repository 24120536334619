import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import "../../css/modal.css"

const ModalProject = ({isModal2Open, handleCloseModal2, textProjet, imageProjet}) => {
    const modalRef = useRef(null);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeModal();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const closeModal = () => {
        setIsClosing(true);
        setTimeout(() => {
            handleCloseModal2();
            setIsClosing(false);
        }, 500);
    };

    return (isModal2Open && window.innerWidth >= 600 && (<div style={{display: "flex", justifyContent: "center"}}>
                <div ref={modalRef} className={`modal ${isClosing ? 'modal-exit' : ''}`} style={{maxWidth: '1000px'}}>
                    <div className="modal-project">
                        <div className="modal-container">
                            <div className="scroll-content">
                                <div className="divClose">
                                  <span className="close" onClick={closeModal}>×</span>
                                </div>
                                <div className="flex flex-col justify-center align-items-center margin-auto">
                                    <div className="text-center text-white text-2xl font-bold p-5">{textProjet}</div>
                                    <div className="text-center flex justify-center items-center"
                                         style={{width: "95%", margin: "auto", maxWidth: "1200px"}}>
                                        {imageProjet}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>));
};

ModalProject.propTypes = {
    isModal2Open: PropTypes.bool.isRequired,
    handleCloseModal2: PropTypes.func.isRequired,
    textProjet: PropTypes.node.isRequired,
    imageProjet: PropTypes.node.isRequired,
};

export default ModalProject;
