import React, {useEffect, useState} from "react";
import cv from "../img/CV Benjamin Elbaz.png";
import lm from "../img/Lettre de motivation.png";
import '../css/style.css';
import pdfCV from '../PDF/CV Benjamin Elbaz.pdf'
import pdfLM from '../PDF/Lettre de motivation.pdf'
import {useTranslation} from "react-i18next";
import {useDarkMode} from "./context/DarkModeContext";

function App() {
    const {t} = useTranslation();
    const {isDarkMode} = useDarkMode();
    const [isCV, setIsCV] = useState(true);
    const [isVisibleCV, setIsVisibleCV] = useState(false);
    const [timeoutDelay, setTimeoutDelay] = useState(0);

    const handlePageLeave = () => {
        setIsVisibleCV(false);
    };
    const handleScroll = () => {
        const element = document.querySelector('.reveal');
        const elementTop = element.getBoundingClientRect().top;
        const elementBottom = element.getBoundingClientRect().bottom;
        const threshold = window.innerWidth < 1024 ? 0.95 : 0.75;

        if (elementTop < window.innerHeight * threshold && elementBottom >= 0) {
            setIsVisibleCV(true);
        } else {
            setIsVisibleCV(false);
        }
    };

    useEffect(() => {
        const imageCV = document.querySelector('.imageCV');
        if (isVisibleCV) {
            if (isCV) {
                imageCV.style.transition = 'transform 1s ease-in-out, opacity 1s ease-in-out'; // Add transition
                imageCV.style.transform = 'translateX(-60%) scale(0.35) rotateY(30deg)'; // Add horizontal rotation
                imageCV.style.opacity = '0'; // Make the image transparent
                // Update the image source after the animation delay
                setTimeout(() => {
                    imageCV.src = cv;
                    imageCV.style.transform = 'translateX(0) scale(1) rotateX(0deg)'; // Reset position, size, and horizontal rotation
                    imageCV.style.opacity = '1'; // Make the image opaque
                }, timeoutDelay); // No delay for the first render, 1000ms delay afterward
            } else {
                imageCV.style.transition = 'transform 1s ease-in-out, opacity 1s ease-in-out'; // Add transition
                imageCV.style.transform = 'translateX(70%) scale(0.35) rotateY(30deg)'; // Add horizontal rotation
                imageCV.style.opacity = '0'; // Make the image transparent

                // Update the image source after the animation delay
                setTimeout(() => {
                    imageCV.src = lm;
                    imageCV.style.transform = 'translateX(0) scale(1) rotateX(0deg)'; // Reset position, size, and horizontal rotation
                    imageCV.style.opacity = '1'; // Make the image opaque
                }, timeoutDelay); // No delay for the first render, 1000ms delay afterward
            }
        }

    }, [isCV]);

    const handleImageChange = () => {
        setIsCV((previsCV) => !previsCV);
    };

    const Next = () => (<svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleImageChange}
        width="64.000000px" height="64.000000px" viewBox="0 0 400.000000 400.000000"
    >
        {/*<g transform="translate(0.000000,1042.000000) scale(0.100000,-0.100000)"*/}
        <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
           stroke="none" fill={isDarkMode ? 'rgba(255,255,255,1)' : 'rgb(18,28,70)'}>
            <path
                d="M1800 3830 c-279 -35 -565 -136 -785 -278 -802 -516 -1083 -1538 -654 -2382 285 -559 825 -931 1451 -999 618 -68 1247 200 1633 694 550 705 521 1684 -71 2355 -267 303 -657 522 -1052 591 -134 23 -404 33 -522 19z m417 -1373 c395 -394 423 -425 423 -457 0 -32 -28 -63 -423 -457 -385 -386 -425-423 -455 -423 -44 0 -82 38 -82 82 0 29 35 68 382 415 l383 383 -383 383 c-347 347 -382 386 -382 415 0 44 38 82 82 82 30 0 70 -37 455 -423z"/>
        </g>
        {isCV && (<animateTransform
            attributeName="transform"
            type="rotate"
            from="180 0 0" // Rotate from 0 degrees around the origin (0, 0)
            to="0 0 0" // Rotate to 80 degrees around the origin (0, 0)
            dur="0.8s" // Duration of the animation in seconds
            repeatCount="1" // Number of times to repeat the animation (1 in this case)
            fill="freeze" // Keeps the final state of the animation after it finishes
        >
        </animateTransform>)}
        {!isCV && (<animateTransform
            attributeName="transform"
            type="rotate"
            from="0 0 0" // Rotate from 0 degrees around the origin (0, 0)
            to="180 0 0" // Rotate to 80 degrees around the origin (0, 0)
            dur="0.8s" // Duration of the animation in seconds
            repeatCount="1" // Number of times to repeat the animation (1 in this case)
            fill="freeze" // Keeps the final state of the animation after it finishes
        >
        </animateTransform>)}
    </svg>)
    const Bull = () => (!isMobile && window.matchMedia('(min-width: 1288px)').matches && (
        <svg xmlns="http://www.w3.org/2000/svg" width="30px">
            <circle cx="0.4cm" cy="1cm" r="5px" className="cursor-pointer"
                    fill={!isCV && isDarkMode ? 'rgba(239,236,236,0.62)' : (!isCV && !isDarkMode ? 'rgba(0,0,0,0.53)' : 'white')}
                    onClick={() => setIsCV(true)} style={{marginRight: '15px'}}/>
        </svg>));
    const Bull1 = () => (!isMobile && window.matchMedia('(min-width: 1288px)').matches && (
        <svg xmlns="http://www.w3.org/2000/svg" width="30px">
            <circle cx="0.4cm" cy="1cm" r="5px" className="cursor-pointer"
                    fill={isCV && isDarkMode ? 'rgba(239,236,236,0.62)' : (isCV && !isDarkMode ? 'rgba(0,0,0,0.53)' : 'white')}
                    onClick={() => setIsCV(false)}/>
        </svg>));


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('beforeunload', handlePageLeave);
        window.addEventListener('unload', handlePageLeave);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('beforeunload', handlePageLeave);
            window.removeEventListener('unload', handlePageLeave);
        };
    }, []);

    const Code = () => (<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="42px" height="42px"
                             viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
           className="svgIconCV"
           stroke="none">
            <path
                d="M438 5064 c-187 -45 -338 -198 -383 -386 -22 -95 -22 -686 1 -733 33 -71 -181 -65 2504 -65 2685 0 2471 -6 2504 65 23 47 23 638 1 733 -45 191 -196 342 -387 387 -91 21 -4152 21 -4240 -1z m497 -480 c35 -16 65 -65 65 -104 0 -39 -30 -88 -65 -104 -23 -11 -63 -16 -135 -16 -72 0 -112 5 -135 16 -35 16 -65 65 -65 104 0 38 30 88 63 104 45 22 226 22 272 0z m640 0 c35 -16 65 -65 65 -104 0 -39 -30 -88 -65 -104 -23 -11 -63 -16 -135 -16 -72 0 -112 5 -135 16 -35 16 -65 65 -65 104 0 38 30 88 63 104 45 22 226 22 272 0z m2880 0 c35 -16 65 -65 65 -104 0 -39 -30 -88 -65 -104 -29 -14 -116 -16 -735 -16 -779 0 -752 -2 -784 65 -20 42 -20 68 0 110 31 67 3 65 782 65 621 0 708 -2 737 -16z"/>
            <path
                d="M103 3624 c-67 -33 -63 78 -63 -1601 0 -1303 2 -1527 15 -1581 45 -191 196 -342 387 -387 96 -22 4141 -22 4237 0 187 44 342 199 386 386 13 56 15 263 15 1582 0 1684 5 1568 -65 1601 -30 14 -270 16 -2457 16 -2180 0 -2426 -2 -2455 -16z m2868 -653 c31 -31 39 -46 39 -75 0 -32 -619 -1722 -671 -1833 -21 -43 -66 -73 -111 -73 -55 0 -118 66 -118 124 0 25 631 1738 671 1822 22 47 65 73 118 74 26 0 42 -9 72 -39z m-1055 -445 c29 -29 34 -41 34 -80 0 -57 -11 -70 -242 -283 -93 -86 -168 -159 -168 -163 0 -5 87 -87 193 -184 105 -96 198 -184 205 -195 17 -29 15 -102 -4 -129 -32 -46 -105 -65 -150 -39 -11 7 -136 117 -277 245 -276 252 -289 269 -267 336 8 25 70 88 233 239 326 300 310 287 364 287 38 0 50 -5 79 -34z m1446 -3 c18 -15 139 -126 270 -245 245 -226 267 -253 252 -312 -4 -17 -86 -100 -238 -239 -340 -312 -309 -287 -362 -287 -39 0 -51 5 -80 34 -28 28 -34 41 -34 78 0 24 7 53 15 66 12 16 320 305 397 371 4 4 -78 85 -183 181 -105 96 -200 186 -210 199 -12 15 -19 40 -19 68 0 38 5 50 34 79 45 45 107 48 158 7z"/>
        </g>
    </svg>)
    const LogoCV = () => (<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px"
                               viewBox="0 0 1024.000000 1024.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
               className="svgIconCV" stroke="none">
                <path
                    d="M1760 5120 l0 -4320 3360 0 3360 0 0 4320 0 4320 -3360 0 -3360 0 0 -4320z m2035 3505 c175 -46 317 -196 354 -373 15 -74 13 -214 -4 -296 -35 -165 -154 -325 -293 -393 -72 -36 -81 -38 -172 -38 -88 0 -101 3 -165 34 -145 71 -264 229 -300 398 -17 81 -19 222 -4 295 56 266 327 439 584 373z m3885 -865 l0 -240 -1200 0 -1200 0 0 240 0 240 1200 0 1200 0 0 -240z m-3779 -570 c297 -30 648 -115 745 -181 38 -26 82 -81 98 -124 4 -11 18 -73 32 -139 28 -140 35 -123 -99 -255 -501 -495 -1289 -547 -1857 -121 -60 45 -211 189 -241 231 -19 27 -19 28 5 145 29 140 31 147 67 200 37 54 89 87 187 118 353 111 743 157 1063 126z m3299 -550 l0 -240 -960 0 -960 0 0 240 0 240 960 0 960 0 0 -240z m640 -3200 l0 -2000 -2720 0 -2720 0 0 2000 0 2000 2720 0 2720 0 0 -2000z"/>
                <path d="M3040 4560 l0 -240 400 0 400 0 0 240 0 240 -400 0 -400 0 0 -240z"/>
                <path d="M4320 4560 l0 -240 1440 0 1440 0 0 240 0 240 -1440 0 -1440 0 0 -240z"/>
                <path d="M3040 3440 l0 -240 400 0 400 0 0 240 0 240 -400 0 -400 0 0 -240z"/>
                <path d="M4320 3440 l0 -240 1440 0 1440 0 0 240 0 240 -1440 0 -1440 0 0 -240z"/>
                <path d="M3040 2320 l0 -240 400 0 400 0 0 240 0 240 -400 0 -400 0 0 -240z"/>
                <path d="M4320 2320 l0 -240 1120 0 1120 0 0 240 0 240 -1120 0 -1120 0 0 -240z"/>
            </g>
        </svg>

    )
    const LogoLM = () => (<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px"
                               viewBox="0 0 1024.000000 1024.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)" stroke="none"
           className="svgIconCV">
            <path
                d="M2390 7116 l0 -2614 1052 -676 1052 -676 626 0 627 0 1050 675 1050 674 6 863 c4 475 7 1188 7 1585 l0 722 -877 3 c-976 3 -914 -1 -965 70 l-23 33 -3 978 -2 977 -1800 0 -1800 0 0 -2614z m2620 1334 l0 -160 -1075 0 -1075 0 0 160 0 160 1075 0 1075 0 0 -160z m2370 -1600 l0 -160 -2260 0 -2260 0 0 160 0 160 2260 0 2260 0 0 -160z m0 -600 l0 -160 -2260 0 -2260 0 0 160 0 160 2260 0 2260 0 0 -160z m0 -610 l0 -160 -2260 0 -2260 0 0 160 0 160 2260 0 2260 0 0 -160z m0 -600 l0 -160 -2260 0 -2260 0 0 160 0 160 2260 0 2260 0 0 -160z"/>
            <path
                d="M6312 8778 l3 -783 768 -3 c444 -1 767 2 765 7 -3 9 -1530 1561 -1535 1561 -2 0 -2 -352 -1 -782z"/>
            <path
                d="M1340 6245 c-387 -327 -706 -598 -708 -603 -2 -7 1409 -922 1423 -922 3 0 5 477 5 1060 0 583 -3 1060 -7 1059 -5 0 -325 -268 -713 -594z"/>
            <path
                d="M8180 5780 c0 -583 2 -1060 5 -1060 12 0 1425 912 1425 920 0 8 -1415 1200 -1425 1200 -3 0 -5 -477 -5 -1060z"/>
            <path
                d="M512 3319 l3 -2004 23 -80 c12 -44 40 -116 62 -160 38 -76 118 -195 131 -195 4 0 107 62 230 138 2805 1733 3174 1962 3174 1972 0 8 -3579 2314 -3613 2327 -10 4 -12 -383 -10 -1998z"/>
            <path
                d="M9555 5215 c-88 -57 -900 -579 -1805 -1160 -905 -581 -1645 -1060 -1645 -1065 0 -9 3390 -2110 3403 -2110 14 0 94 119 131 195 22 44 50 116 63 160 l23 80 3 2003 c1 1101 -1 2002 -5 2002 -5 -1 -80 -48 -168 -105z"/>
            <path
                d="M2748 1747 c-961 -595 -1748 -1085 -1748 -1088 0 -8 121 -64 192 -88 31 -11 94 -28 140 -38 80 -17 232 -18 3788 -18 3556 0 3708 1 3788 18 46 10 109 27 140 38 71 25 192 80 192 88 0 4 -219 142 -487 307 -269 166 -1056 653 -1751 1083 l-1263 781 -622 -1 -622 -1 -1747 -1081z"/>
        </g>
    </svg>)

    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    return (<div className="font-nunito">
        <div id="CV" className="bg-gradient-to-t from-indigo-200 dark:from-slate-800 dark:to-slate-900"
             style={{
                 overflow: "hidden",
                 paddingTop: isMobile ? "3.95rem" : "5rem",
                 paddingBottom: isMobile ? "3rem" : "5rem"
             }}>
            <div className="container containerCV mx-auto flex flex-col-reverse lg:flex-row items-center">
                {/*fist colonne*/}
                <div>
                    <a href={isCV ? pdfCV : pdfLM} target="_blank" rel="noopener noreferrer">
                        <img
                            className={`imageCV reveal ${isVisibleCV ? 'is-visibleCV' : ''}`}
                            src={cv}
                            width="100%"
                            height="100%"
                            alt=""
                            title={t('Show')}
                        />
                    </a>
                </div>
                {/*second colonne*/}
                <div className={` flex reveal2 ${isVisibleCV ? 'is-visibleCV' : ''}`}>
                    <div style={{margin: "auto", textAlign: "center", paddingBlockEnd: "15px"}}>
                        <div style={{display: isMobile ? "block" : "none"}}>
                            <h1
                                key={isCV}
                                style={{paddingBottom: "1rem"}}
                                className="white:text-gray-100 text-titreCV animate-fade-in-scaleContact">
                                {isCV ? "CV" : t("LM")}
                            </h1>
                        </div>
                        <div className="switch-button" style={{display: "flex", justifyContent: "center", cursor: "pointer"}}
                             title={isCV ? "CV" : t("LM")} onClick={() => setTimeoutDelay(1000)}>
                            <Next/>
                        </div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                                <Bull/>
                                <Bull1/>
                        </div>
                    </div>
                </div>
                {/*thirs colonne*/}
                <div style={{display: isMobile ? "none" : "block"}}>
                    <div className={`mx-auto flex reveal ${isVisibleCV ? 'is-visibleCV' : ''}`}
                         style={{
                             display: "flex", flexDirection: "column", alignItems: "center", margin: "1%", gap: "2rem"
                         }}>
                        {/*cv*/}
                        <div
                            key={isCV ? true : ''}
                            className={`animate-fade-in-scaleContact ${isCV ? "" : "selectCV"}`}
                            style={{padding: "14px", border: isCV ? isDarkMode ? "3px solid #eff6f1" : "3px solid #000000" : "", borderRadius: "18px", background: isCV ? isDarkMode ? "rgba(122,252,194,0.51)" : "rgba(56, 229, 157, 0.23)" : ""}}
                            onClick={() => {
                                setIsCV(true);
                                setTimeoutDelay(1000);
                            }}>
                            <div style={{
                                paddingBottom: '5%',
                                padding: '10px',
                                paddingLeft: "20px",
                                paddingRight: "20px",
                            }}>
                                <h1 className="text-center white:text-gray-100 text-titreCV font-bold select-none">Curriculum
                                    Vitæ</h1>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    paddingBottom: "5px",
                                    paddingTop: "5px",
                                    justifyContent: "center",
                                    gap: "15px",
                                    paddingBlockEnd: "15px"
                                }}>
                                    <LogoCV/>
                                    <Code/>
                                </div>
                            </div>
                            <div className="text-gray-400 pb-cv text-center space-x-10">
                                <a href={pdfCV} download={"CV Benjamin Elbaz"}>
                                    <button
                                        className="buttonCV buttonCVGreen bg-indigo-600 text-white-1 text-xl text-oneUI px-3 py-2 rounded-md font-semibold w-fit"
                                        style={{textTransform: "none"}}>{t('Download')}
                                    </button>
                                </a>
                            </div>
                        </div>
                        {/*lm*/}
                        <div
                            key={isCV ? '' : false}
                            className={`animate-fade-in-scaleContact ${!isCV ? "" : "selectLM"}`}
                            style={{border: !isCV ? isDarkMode ? "3px solid #eff6f1" : "3px solid #000000" : "", borderRadius: "18px", background: !isCV ? isDarkMode ? "rgba(104, 198, 220, 0.33)" : "rgba(104, 198, 220, 0.23)" : ""}}
                            onClick={() => {
                                setIsCV(false);
                                setTimeoutDelay(1000);
                            }}>
                            <div>
                                <div style={{paddingTop: "10%", paddingBottom: '5%', padding: '10px'}}>
                                    <h1 className="text-center white:text-gray-100 text-titreCV font-bold select-none">{t('LM')}</h1>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        paddingBottom: "5px",
                                        paddingTop: "5px",
                                        justifyContent: "center",
                                        gap: "15px",
                                    }}>
                                        <LogoLM/>
                                    </div>
                                </div>
                                <div className="text-gray-400 pb-cv text-center space-x-10">
                                    <a href={pdfLM} download={"LM Benjamin Elbaz"}>
                                        <button
                                            className="buttonCV buttonCVBlue bg-indigo-600 text-white-1 text-xl text-oneUI px-3 py-2 rounded-md font-semibold w-fit"
                                            style={{textTransform: "none"}}>{t('Download')}
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default App;
