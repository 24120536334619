import '../css/style.css';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, {useEffect, useRef, useState} from 'react';
import {Form} from "react-final-form";
import {useTranslation} from "react-i18next";
import * as emailjs from "@emailjs/browser";
import {useInView} from "react-intersection-observer";
import {useDarkMode} from "./context/DarkModeContext";

export const ContactUs = () => {
    const form = useRef();
    const {isDarkMode} = useDarkMode();
    const MailLogo = () => (
        <svg version="1.1" x="0px" y="0px" fill="transparent"
             width="50px" viewBox="0 0 512 512">
            <path opacity="1.000000" stroke="none"
                  d="M344.000000,513.000000 C229.361191,513.000000 115.222374,513.000000 1.041779,513.000000 C1.041779,342.402832 1.041779,171.805649 1.041779,1.104233 C171.555481,1.104233 342.111053,1.104233 512.833313,1.104233 C512.833313,171.666534 512.833313,342.333252 512.833313,513.000000 C456.805573,513.000000 400.652802,513.000000 344.000000,513.000000 M468.945465,138.118195 C463.786438,119.441864 447.416229,107.113663 427.771729,107.083443 C315.976807,106.911484 204.181549,106.987762 92.386414,107.042404 C87.640503,107.044724 82.859154,107.406364 78.155746,108.049744 C62.881912,110.139069 48.343407,123.537598 45.009411,138.711395 C44.389183,146.170609 43.768955,153.629837 43.016487,162.006805 C43.011024,227.821045 42.989273,293.635284 43.042629,359.449493 C43.045670,363.197845 43.573502,366.956207 44.009949,370.692047 C46.421684,391.335693 64.931038,406.930756 86.292076,406.950806 C198.426178,407.056122 310.560425,407.010468 422.694580,406.957916 C426.774628,406.956024 430.874512,406.493256 434.930939,405.976776 C455.366394,403.375000 470.855988,385.305359 470.923157,364.028625 C471.132477,297.715179 471.001099,231.400620 470.993195,165.086502 C470.993011,163.423340 470.892548,161.760162 470.968506,159.161392 C470.254852,152.400681 469.541199,145.639969 468.945465,138.118195 z"/>
            <path fill="#CFD8DC" opacity="1.000000" stroke="none"
                  d=" M470.838867,160.097000 C470.892548,161.760162 470.993011,163.423340 470.993195,165.086502 C471.001099,231.400620 471.132477,297.715179 470.923157,364.028625 C470.855988,385.305359 455.366394,403.375000 434.930939,405.976776 C430.874512,406.493256 426.774628,406.956024 422.694580,406.957916 C310.560425,407.010468 198.426178,407.056122 86.292076,406.950806 C64.931038,406.930756 46.421684,391.335693 44.009949,370.692047 C43.573502,366.956207 43.045670,363.197845 43.042629,359.449493 C42.989273,293.635284 43.011024,227.821045 43.398888,161.647614 C66.319084,177.534393 88.884575,193.742142 111.388870,210.034424 C156.948105,243.017654 202.466309,276.057587 248.020401,309.047913 C257.262177,315.740845 257.225861,315.584198 266.654266,308.742249 C325.380035,266.126556 384.155151,223.578888 442.878784,180.960236 C452.289337,174.130524 461.523285,167.057541 470.838867,160.097000 z"/>
            <path fill="#CFD8DC" opacity="1.000000" stroke="none"
                  d="M45.173855,137.895752 C48.343407,123.537598 62.881912,110.139069 78.155746,108.049744 C82.859154,107.406364 87.640503,107.044724 92.386414,107.042404 C204.181549,106.987762 315.976807,106.911484 427.771729,107.083443 C447.416229,107.113663 463.786438,119.441864 468.649170,138.306488 C467.045319,138.647888 465.409760,138.346512 464.477448,139.019440 C422.223419,169.517532 380.028870,200.097992 337.832153,230.675507 C312.216827,249.237457 286.571045,267.757904 261.048248,286.446350 C257.955963,288.710602 255.809082,288.735718 252.862762,286.351837 C248.339981,282.692383 243.457535,279.478455 238.739700,276.058929 C175.660309,230.338257 112.588226,184.607513 49.462906,138.950378 C48.357323,138.150726 46.617649,138.227783 45.173855,137.895752 z"/>
            <path fill="#79919D" opacity="1.000000" stroke="none"
                  d="M45.091633,138.303574 C46.617649,138.227783 48.357323,138.150726 49.462906,138.950378 C112.588226,184.607513 175.660309,230.338257 238.739700,276.058929 C243.457535,279.478455 248.339981,282.692383 252.862762,286.351837 C255.809082,288.735718 257.955963,288.710602 261.048248,286.446350 C286.571045,267.757904 312.216827,249.237457 337.832153,230.675507 C380.028870,200.097992 422.223419,169.517532 464.477448,139.019440 C465.409760,138.346512 467.045319,138.647888 468.590210,138.687012 C469.541199,145.639969 470.254852,152.400681 470.903687,159.629196 C461.523285,167.057541 452.289337,174.130524 442.878784,180.960236 C384.155151,223.578888 325.380035,266.126556 266.654266,308.742249 C257.225861,315.584198 257.262177,315.740845 248.020401,309.047913 C202.466309,276.057587 156.948105,243.017654 111.388870,210.034424 C88.884575,193.742142 66.319084,177.534393 43.465012,161.188721 C43.768955,153.629837 44.389183,146.170609 45.091633,138.303574 z"/>
        </svg>
    )
    const sendEmail = (e) => {
        e.preventDefault();
        const isFormValid = form.current.checkValidity();
        if (isFormValid) {
            emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
                .then(() => {
                    // console.log(result.text);
                    setAnimate(true);
                    setTimeout(() => {
                        setAnimate(false);
                    }, 1500);
                    const notify = () => toast(t('messagesend'), {
                        style: {background: '#2f3130', color: "white"},
                        icon: <img width="30" height="30" style={{}} src="https://img.icons8.com/stickers/100/sent.png" alt="sent"/>,
                    });
                    document.getElementById("name").value = "";
                    document.getElementById("email").value = "";
                    document.getElementById("message").value = "";
                    notify();
                }, () => {
                    const notify = () => toast.error(t('messagenotsend'), {
                        style: {background: '#2f3130', color: "white"},
                    });
                    notify();
                });

        } else {
            // Affichez un message d'erreur ou effectuez une action appropriée lorsque le formulaire n'est pas valide.
            const notify = () => toast.error(t('formError'), {
                style: {background: '#2f3130', color: "white"},
            });
            notify();
        }
    };

    const {t} = useTranslation()
    const [inView] = useInView({threshold: 0.6, triggerOnce: false});
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (inView) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [inView]);

    const handleNameInput = (event, msg) => {
        const nameInput = event.target;
        if (nameInput.validity.valueMissing) {
            nameInput.setCustomValidity(msg);
        } else {
            nameInput.setCustomValidity("");
        }
    };

    const handleEmailInput = (event, msg) => {
        const nameInput = event.target;
        const email = nameInput.value;

        if (nameInput.validity.valueMissing) {
            nameInput.setCustomValidity(msg);
        } else if (!email.includes("@")) {
            nameInput.setCustomValidity("Missing \"@\" symbol in " + nameInput.value);
        } else {
            const atIndex = email.indexOf("@");
            const lastDotIndex = email.lastIndexOf(".");
            if (lastDotIndex < atIndex) {
                nameInput.setCustomValidity(
                    "The location of the '.' is incorrect in the email address"
                );
            } else {
                nameInput.setCustomValidity("");
            }
        }
    };


    const [animate, setAnimate] = useState(false);

    const handleClick = () => {
        form.current.reportValidity(); // Force la validation du formulaire
    };

    return (
        <div className="font-nunito">
            <div id="ContactPage" className="bg-gradient-to-t dark:to-slate-1000 white:to-slate-1000 text-select"
                 style={{overflowX: "hidden", height: "100Vh", paddingTop: "2rem"}}>
                <div className="container mx-auto" style={{paddingTop: "3rem"}}>
                    <div className="flex flex-col gap-3 items-center titrecontact">
                        <h1 className="text-titre1 font-bold">Contact</h1>
                    </div>
                    <Form className="color mt-5 p-8 flex flex-col gap-5 items-center"
                          onSubmit={sendEmail}
                          render={() => (
                              <form ref={form} onSubmit={sendEmail}
                                    className="mt-5 flex flex-col gap-5 items-center">
                                  <ToastContainer/>
                                  <input
                                      className="p-2 w-full-contact md:w-1/2Contact rounded-sm dark:bg-slate-800 white:bg-slate-700 dark:ring-0 dark:text-white input-contact"
                                      id="name"
                                      name="user_name"
                                      type="text"
                                      placeholder={t('nom')}
                                      maxLength="25"
                                      required
                                      onInvalid={(event) => handleNameInput(event, t('form'))}
                                      // onInput={(event) => event.target.setCustomValidity("")}
                                  />
                                  <input
                                      className="p-2 w-full-contact md:w-1/2Contact rounded-sm dark:bg-slate-800 white:bg-slate-700 dark:ring-0 dark:text-white input-contact"
                                      id="email"
                                      name="user_email"
                                      type="email"
                                      placeholder="Email"
                                      maxLength="30"
                                      required
                                      onInvalid={(event) => handleEmailInput(event, t('formEmail'))}
                                  />
                                  <textarea
                                      className="p-2 w-full-contact md:w-1/2Contact rounded-sm dark:bg-slate-800 white:bg-slate-700 dark:ring-0 dark:text-white input-contact mobile-textarea"
                                      maxLength="800"
                                      id="message"
                                      name="message"
                                      cols="30"
                                      rows="10"
                                      type="text"
                                      placeholder="Message . . ."
                                      required
                                      onInvalid={(event) => handleNameInput(event, t('form'))}
                                  ></textarea>
                                  <button className="button-send switch-button" onClick={handleClick} type="submit">
                                      <div className="text-send">Send</div>
                                      <div
                                          className={`icon-send ${animate ? 'animate-out-send' : ''} ${!animate && isVisible ? 'animate-back-send' : ''}`}>
                                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                               xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                               width="388.6px" height="388.6px" viewBox="0 0 388.6 388.6"
                                               style={{enableBackground: 'new 0 0 388.6 388.6'}}
                                               xmlSpace="preserve">
                                              <style type="text/css">{`.st0{}`}</style>
                                              <defs></defs>
                                              <polygon
                                                  className="st0"
                                                  points="173.5,215.5 215.5,369.6 369.6,19.4 369.2,19 19,173.1 173.1,215.1"
                                              />
                                          </svg>
                                      </div>
                                  </button>
                              </form>)}/>
                </div>
                <div style={{paddingTop: "2rem"}}>
                    <button className="flex items-center mx-auto"
                            onClick={() => window.location.href = "mailto:benjam.elbaz@gmail.com"}
                            title="benjam.elbaz@gmail.com">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: "center",
                            paddingBottom: "5px"
                        }}>
                            <MailLogo/>
                            <div>
                                <p style={{color: isDarkMode ? "white" : "black"}}>benjam.elbaz@gmail.com</p>
                            </div>
                        </div>
                    </button>
                </div>
                {/*toast*/}
                <div>
                    <ToastContainer/>
                </div>
                <div style={{justifyContent: "center", display: "flex"}}>
                    <div>
                        <p className="text-center text-benjamin dark:text-white cursor-pointer p-5">&copy; 2024 Benjamin
                            Elbaz,
                            Coded with ❤️</p>
                    </div>
                </div>
            </div>

            {/*<button style={{color:"white"}} onClick={()=>toast(t('messagesend'), {*/}
            {/*    style: {background: '#2f3130', color: "white"},*/}
            {/*    icon: <img width="30" height="30" style={{}} src="https://img.icons8.com/stickers/100/sent.png" alt="sent"/>,*/}
            {/*})}>TEST NOTIF</button>*/}

            {/*<div className="bg-gradient-to-t dark:from-slate-800 dark:to-slate-900">*/}
            {/*<div className="bg-gradient-to-t from-indigo-200 dark:from-slate-800 dark:to-slate-1000">*/}
            {/*    <p className="text-center text-sm dark:text-white">2023 Benjamin Elbaz v3.4</p>*/}
            {/*</div>*/}
        </div>);
};


class App extends React.Component {
    render() {
        return (
            <div>
                <ContactUs/>
            </div>);
    }
}

export default App;
