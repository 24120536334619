import React, {useEffect, useState} from 'react';
import '../css/page.css';
import VanillaTilt from 'vanilla-tilt';
import {useTranslation} from "react-i18next";


const App = () => {

    useEffect(() => {
        VanillaTilt.init(document.querySelector('.box'), {
            max: 15,
            speed: 400,
            glare: true,
            "max-glare": 0.3
        });
    }, []);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const {t} = useTranslation()
    useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div>
            {screenWidth < 390 ? (
                <div className="error-message">
                    <span className="close-button" onClick={() => {
                        window.location.href = window.location.origin;
                    }}>&times;</span>
                    <p>{t('page1')}.</p>
                    <p>{t('page2')}.</p>
                </div>
            ) : (
                <a href="#" onClick={() => {
                    window.location.href = window.location.origin;
                }}>

                    <div className="bodyMe" style={{transition: '1s'}}>
                        <div>
                            <div>
                                <p className="neon-text text-oneUI h2">
                                    <b className="neon-text-1 text-oneUI">Benjamin</b>
                                    <b>Elbaz</b>
                                </p>
                            </div>
                            <div className="box" data-tilt="" data-tilt-full-page-listening="" data-tilt-reset="false"
                                 data-tilt-transition="false">
                                <div className="innerbox"
                                     onClick={(event) => {
                                         event.stopPropagation();
                                     }}
                                >
                                    <h1 className="titreMe text-oneUI">© Projet Porfolio</h1>
                                    <h1 className="titreMe2 text-oneUI">2024</h1>
                                    <p className="nameMe cursor-pointer text-oneUI" title="React is cool &#x1F913;" onClick={() => {
                                        window.location.href ='Page2'
                                    }}>
                                        Benjamin Elbaz
                                    </p>

                                    <p className="text-center text-white text-card text-oneUI" style={{paddingTop: "1.3rem",paddingBottom: "1rem",fontSize : "25px"}}>
                                        React ,  JS ,  CSS ,  HTML
                                    </p>

                                    <button className="nameMe2 cursor-pointer" onClick={() => {
                                        window.location.href = '*'
                                    }}>
                                        <p className="nameMe2 text-oneUI">
                                            404 page
                                        </p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            )}
        </div>
    );

}

export default App;
