import "../../css/animation.css";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import BackgroundAnimation from "./backgroundAnimation";

const Animation = () => {
    const {t} = useTranslation();
    const canvasRef = useRef(null);
    const titleRef = useRef(null);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        let animationFrameId;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const title = titleRef.current;

        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        };

        const precomputedSinCos = Array.from({length: 400}, (_, i) => ({
            sin: Math.sin(i),
            cos: Math.cos(i),
        }));

        const draw = (t) => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = "hsla(0,0%,0%,0)";
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            const foo = Math.sin(t / 2) * 3 * Math.PI;
            for (let i = 0; i < 400; ++i) {
                const r = 400 * Math.sin(i * foo);
                ctx.beginPath();
                ctx.arc(
                    precomputedSinCos[i].sin * r + canvas.width / 2,
                    precomputedSinCos[i].cos * r + canvas.height / 2,
                    1.5,
                    0,
                    Math.PI * 2
                );
                ctx.closePath();
                ctx.fillStyle = `hsla(${i + 12},100%, 60%,1)`;
                ctx.fill();
            }
            t += 0.0000058;
            t %= 2 * Math.PI;

            if (isVisible) {
                animationFrameId = requestAnimationFrame(() => draw(t));
            }
        };

        const resizeTitle = () => {
            const viewportWidth = window.innerWidth;
            const fontSize = Math.max(2, Math.min(30, viewportWidth / 30));
            title.style.fontSize = `${fontSize}px`;
        };

        const handleVisibilityChange = () => {
            setIsVisible(document.visibilityState === "visible");
        };

        resizeCanvas();
        animationFrameId = requestAnimationFrame(() => draw(0));
        resizeTitle();
        window.addEventListener("resize", resizeCanvas);
        window.addEventListener("resize", resizeTitle);
        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            window.removeEventListener("resize", resizeCanvas);
            window.removeEventListener("resize", resizeTitle);
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            cancelAnimationFrame(animationFrameId);
        };
    }, []);

    return (
        <div className="animation">
            <canvas className="py-20" ref={canvasRef}></canvas>
            <div><BackgroundAnimation/></div>
            <div className="titleContainer">
                <h1 ref={titleRef} className="title">
                    {t("Welcome")}
                </h1>
                <h1 ref={titleRef} className="title">
                    {t("Portfolio")}
                </h1>
            </div>
        </div>
    );
};

export default Animation;
