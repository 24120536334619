// DarkModeContext.js
import React, {createContext, useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import Cookies from "js-cookie";

const DarkModeContext = createContext();

export function useDarkMode() {
    return useContext(DarkModeContext);
}

export function DarkModeProvider({children}) {
    const storedDarkMode = Cookies.get('isDarkMode');
    const systemPrefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [isDarkMode, setIsDarkMode] = useState(
        storedDarkMode === 'true' || (storedDarkMode === undefined && systemPrefersDark));

    const setMode = () => {
        setIsDarkMode((prevMode) => !prevMode);
    };

    useEffect(() => {
        const body = document.querySelector('body');
        if (isDarkMode) {
            body.classList.add('dark');
        } else {
            body.classList.add('white');
        }

        Cookies.set('isDarkMode', isDarkMode.toString(), {expires: 365}); // Vous pouvez définir une expiration appropriée
    }, [isDarkMode]);

    return (
        <DarkModeContext.Provider value={{isDarkMode, setMode}}>
            {children}
        </DarkModeContext.Provider>
    );
}

DarkModeProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
