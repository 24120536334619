import React from 'react';
import '../css/style.css';
import '../css/page.css';

const Page2 = () => {
    return (
        <div>
            <div
                className="portal"
                style={{ pointerEvents: 'auto',position: "fixed",left: "0px",top:"0px", height: "100vh", width: "100vw"}} // Utilisation de style comme objet
            >
                <div>
                    <p className="text-portal text-oneUI text-select">Benjamin Elbaz</p>
                    <p className="text-hover text-portal text-oneUI text-select" style={{paddingTop: "9rem",fontSize: "35px"}}>Please Wait ...</p>
                    <div className="text-portal loadPage loadPageItem">
                        <div className="dot dot1"></div>
                        <div className="dot dot2"></div>
                        <div className="dot dot3"></div>
                    </div>
                </div>
                <div className="portal-inner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>

        </div>
    );
}

export default Page2;
