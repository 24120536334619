import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useInView} from "react-intersection-observer";
import '../css/style.css';
import SVGComponent from "./context/SvgComponent";

function App() {
    const {t} = useTranslation();
    const {ref, inView} = useInView();

    const popups = useRef({});

    const handleMouseOver = (e, key) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const popup = popups.current[key];
        popup.style.top = `${rect.bottom + window.scrollY}px`;
        popup.style.left = `${rect.left + window.scrollX}px`;
        popup.style.visibility = 'visible';
    };

    const handleMouseOut = (key) => {
        const popup = popups.current[key];
        popup.style.visibility = 'hidden';
    };

    return (
        <div className="font-nunito">
            <div id="Skills" className="dark:bg-slate-901 white:bg-slate-1000 skills">
                <div className="container mx-auto select-none">
                    <h1 className="text-center text-titre1 font-bold text-3xl dark:text-white">
                        {t('Competences')}
                    </h1>
                    <div ref={ref}
                         className="py-10 container mx-auto flex flex-col-reverse lg:flex-row items-center gap-20 container-skills-outer-wrapper">
                        <div
                            className="h-1/2 w-1/2 my-auto flex flex-col container-skills-wrapper container-skills-wrapper-1">
                            <div className="container-skills">
                                <p className="text-3xl text-center text-white white:text-white"
                                   style={{paddingBottom: '2rem'}}>
                                    {t('Langage')}
                                </p>
                                {['HTML', 'PHP', 'Symfony', 'JavaScript', 'React'].map((skill, index) => (
                                    <div
                                        className="popup cursor"
                                        key={skill}
                                        onMouseOver={(e) => handleMouseOver(e, skill)}
                                        onMouseOut={() => handleMouseOut(skill)}
                                    >
                                        <h2 className="text-gray-400 py-2 font-medium white:text-white cursor-pointer">{skill}</h2>
                                        <div>
                                            <div
                                                className={`cursor-pointer w-${index + 1}/6 bg-bar1 h-1.5 rounded-md ${inView ? 'animate-loading' : ''}`}></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div
                            className="w-1/2 my-auto flex flex-col container-skills-wrapper container-skills-wrapper-2">
                            <div className="container-skills">
                                <p className="text-3xl text-center dark:text-white white:text-white"
                                   style={{paddingBottom: '1.5rem'}}>
                                    {t('LangageProg')}
                                </p>
                                {['Java', 'C', 'Python', 'C#', 'SQL'].map((skill, index) => (
                                    <div
                                        className="popup cursor"
                                        key={skill}
                                        onMouseOver={(e) => handleMouseOver(e, skill)}
                                        onMouseOut={() => handleMouseOut(skill)}
                                    >
                                        <h2 className="text-gray-400 py-2 font-medium white:text-white cursor-pointer">{skill}</h2>
                                        <div className="w-full bg-gray-200 h-1.5 rounded-md cursor-pointer">
                                            <div
                                                className={`w-${index + 1}/6 bg-bar2 h-1.5 rounded-md ${inView ? 'animate-loading' : ''}`}></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="popup-text-container">
                        {['HTML', 'PHP', 'Symfony', 'JavaScript', 'React', 'Java', 'C', 'Python', 'C#', 'SQL'].map(skill => (
                            <span
                                className="popup-text"
                                id={`${skill.toLowerCase()}-popup`}
                                ref={el => (popups.current[skill] = el)}
                                key={skill}
                            >
                                {t(skill)}
                            </span>
                        ))}
                    </div>
                    <div>
                        <SVGComponent/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
