import React, {useEffect} from 'react';
import "../css/style.css"
import pdfFile from '../PDF/CV Benjamin Elbaz.pdf';
import image from '../img/logo512.png'
const PdfViewer = () => {
    // Suppression de l'effet qui ouvre automatiquement le PDF
    useEffect(() => {
        window.open(pdfFile, '_blank');
    }, []);

    const handlePdfClick = () => {
        window.open(pdfFile, '_blank');
    };


    return (
        <div
            className="text-oneUI animate-fade-in-scaleContact"
            style={{
                position: "fixed",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                background: '#101825'
            }}
        >
            <div style={{
                textAlign: "center",
                border: "4px solid white",
                background: "rgba(111,241,195,0.53)",
                margin: "15px",
                borderRadius: "15px"
            }}>
                <div style={{textAlign: "center", margin: "75px 120px"}}>
                    <img width="140" height="147" style={{paddingBottom: "10px"}} src={image}
                         alt="sent"/>
                    <h1 style={{fontSize: "25px", color: "white", paddingBottom: "25px", userSelect: "none"}}>Benjamin
                        Elbaz</h1>
                    <div style={{color: "white", paddingTop: "10px"}}>
                        <button
                            style={{color: "black", zIndex: "10"}}
                            className="buttonCV2"
                            onClick={handlePdfClick} // Ajout de l'événement onClick
                        >
                            Ouvrir le CV
                        </button>
                    </div>
                    <div style={{paddingTop: "35px"}}>
                        <button
                            style={{
                                color: "white",
                                paddingTop: "10px",
                                fontSize: "18px",
                                margin: "auto",
                                border: "2px white solid"
                            }}
                            className="button-send"
                            onClick={() => window.location.href = '/'}
                        >
                            Portfolio
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PdfViewer;
