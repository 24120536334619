// BlurContext.js
import React, { createContext, useState, useContext } from 'react';

const BlurContext = createContext();

export const BlurProvider = ({ children }) => {
    const [isBlurred, setIsBlurred] = useState(false);

    return (
        <BlurContext.Provider value={{ isBlurred, setIsBlurred }}>
            {children}
        </BlurContext.Provider>
    );
};

export const useBlur = () => useContext(BlurContext);
