import React from "react";
import ReactDOM from "react-dom/client";
import App from './App'
//vercel
import {inject} from '@vercel/analytics';
import {SpeedInsights} from "@vercel/speed-insights/react"

inject();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <SpeedInsights/>
        <App/>
    </React.StrictMode>
);

